import { Subscriber } from '../Subscriber';
import { async } from '../scheduler/async';
export function sampleTime(period, scheduler = async) {
  return source => source.lift(new SampleTimeOperator(period, scheduler));
}
class SampleTimeOperator {
  constructor(period, scheduler) {
    this.period = period;
    this.scheduler = scheduler;
  }
  call(subscriber, source) {
    return source.subscribe(new SampleTimeSubscriber(subscriber, this.period, this.scheduler));
  }
}
class SampleTimeSubscriber extends Subscriber {
  constructor(destination, period, scheduler) {
    super(destination);
    this.period = period;
    this.scheduler = scheduler;
    this.hasValue = false;
    this.add(scheduler.schedule(dispatchNotification, period, {
      subscriber: this,
      period
    }));
  }
  _next(value) {
    this.lastValue = value;
    this.hasValue = true;
  }
  notifyNext() {
    if (this.hasValue) {
      this.hasValue = false;
      this.destination.next(this.lastValue);
    }
  }
}
function dispatchNotification(state) {
  let {
    subscriber,
    period
  } = state;
  subscriber.notifyNext();
  this.schedule(state, period);
}
